/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/media-has-caption */
import MinimizeIcon from "@mui/icons-material/Minimize";
import { Avatar, Box, IconButton, Stack } from "@mui/material";
import { SocketContext } from "SocketContex";
import { SOCKET_URL } from "config";
import useDecodedData from "hooks/useDecodedData";
import { useContext } from "react";
import { getInitials } from "utils/get-initials";

function VideConference(props: any) {
  const { socketConnectionId } = props;
  const { videoConfId, setCallPopup, callPopup, onConfCall } =
    useContext(SocketContext);
  const decoded = useDecodedData();
  const url = `${SOCKET_URL}/room.html?room=${videoConfId?.roomId
    }&username=${encodeURIComponent(decoded.FullName)}&userid=${decoded.id
    }&socketConnectionId=${socketConnectionId}&isVideo=${videoConfId?.isVideo === true ? 1 : 0
    }`;
  console.log(url);
  return (
    <Box

      sx={{
        height: callPopup ? "1px" : "100%",
        width: callPopup ? "1px" : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #bdbdbd",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: callPopup ? -1 : 99999,

        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#f7f7f7",
          padding: "5px 16px",
          height: "8%",
          width: "100%",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginRight: "8px" }}>
            <Avatar>{getInitials(videoConfId?.groupName)}</Avatar>
          </Box>
          <Box>{videoConfId?.groupName}</Box>
        </Box>
        <Stack alignItems="center" direction="row">
          <IconButton
            onClick={() => {
              // handleClose();
              setCallPopup?.(true);
            }}
          >
            <MinimizeIcon />
          </IconButton>
        </Stack>
      </Box>

      <iframe
        allow="microphone *; camera *; display-capture *"
        height="100%"
        src={url}
        width="100%"
      />
    </Box>
  );
}

export default VideConference;
